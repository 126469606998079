import { Select } from "antd";
import classNames from "classnames";
import { useInjection } from "inversify-react";
import { useCallback } from "react";
import { CaseApi, CaseStatus, CaseType, ProductCaseType } from "../../api";
import { CaseDetailsMainTileProps } from "../../pages/case/caseDetails/caseDetailsMainTile";
import ApiService from "../../services/apiService";
import styles from "./caseStatusSelector.module.less";

const { Option } = Select;

export function getNameForCaseStatus(status?: CaseStatus) {
    switch (status) {
        case CaseStatus.InProgress: return "W toku";
        case CaseStatus.Done: return "Zakończona";
        case CaseStatus.New: return "Zgłoszona";
        case CaseStatus.Offering: return "Ofertowanie";
        default: return "Brak";
    }
}

export default function CaseStatusSelector(props: CaseDetailsMainTileProps) {
    const apiService = useInjection(ApiService);
    const { editable, currentCase, updateCase } = props;
    const { id } = currentCase;

    const value = currentCase.status;

    const statusClassName = (() => {
        switch (value) {
            case CaseStatus.New: return styles.new;
            case CaseStatus.InProgress: return styles.inProgress;
            case CaseStatus.Done: return styles.done;
            case CaseStatus.Offering: return styles.offering;
        }
    })();

    const changeStatus = useCallback(async (status: CaseStatus) => {
        const response = await apiService.getApi(CaseApi).apiCaseIdStatusPut(id!, status);

        return response.data;
    }, [apiService, id]);

    const onChange = useCallback((status: CaseStatus) => {
        return updateCase(() => changeStatus(status));
    }, [changeStatus, updateCase]);

    const className = editable ? classNames(styles.wrapper, statusClassName) : classNames(styles.wrapper, statusClassName, styles.nonEditable);

    return <Select
            dropdownMatchSelectWidth={false}
            showArrow={editable}
            disabled={!editable}
            value={value}
            onChange={onChange}
            className={className}>
        <Option value={CaseStatus.New} className={classNames(styles.option, styles.new)}>{getNameForCaseStatus(CaseStatus.New)}</Option>
        <Option value={CaseStatus.Offering} className={classNames(styles.option, styles.offering)}>{getNameForCaseStatus(CaseStatus.Offering)}</Option>
        <Option value={CaseStatus.InProgress} className={classNames(styles.option, styles.inProgress)}>{getNameForCaseStatus(CaseStatus.InProgress)}</Option>
        <Option value={CaseStatus.Done} className={classNames(styles.option, styles.done)}>{getNameForCaseStatus(CaseStatus.Done)}</Option>
    </Select>;
}