import {Select, SelectProps, Skeleton} from "antd";
import { getDepartments, setDepartments } from "../../redux/productSlice";
import { useDispatch, useSelector } from "react-redux";
import { useInjection } from "inversify-react";
import ApiService from "../../services/apiService";
import { useEffect } from "react";
import { DepartmentApi } from "../../api";

const { Option } = Select;

export default function DepartmentSelector(props: SelectProps) {

    const dispatch = useDispatch();
    const apiService = useInjection(ApiService);
    const departments = useSelector(getDepartments);

    useEffect(() => {
        const updateDepartments = async () => {
            const response = await apiService.getApi(DepartmentApi).apiDepartmentGet();

            dispatch(setDepartments(response.data));
        }

        updateDepartments();
    }, [apiService]);

    if (!departments) {
        return <Skeleton.Input />
    }

    return <Select
            style={{width: "100%"}}
            dropdownMatchSelectWidth={false}
            allowClear={true}
            placeholder="Departament / dziedzina"
            {...props}>
            {departments.map((d, i) => <Option value={d.id} key={d.id}>{d.name}</Option>)}
    </Select>;
}

export function GweOrganisationDepartmentSelector(props: SelectProps) {

    const dispatch = useDispatch();
    const apiService = useInjection(ApiService);
    const departments = useSelector(getDepartments);

    useEffect(() => {
        const updateDepartments = async () => {
            const response = await apiService.getApiWithOrganisation(DepartmentApi, 0).apiDepartmentGet();

            dispatch(setDepartments(response.data));
        }

        updateDepartments();
    }, [apiService]);

    if (!departments) {
        return <Skeleton.Input />
    }

    return <Select
            style={{width: "100%"}}
            dropdownMatchSelectWidth={false}
            allowClear={true}
            placeholder="Departament / dziedzina"
            {...props}>
            {departments.map((d, i) => <Option value={d.id} key={d.id}>{d.name}</Option>)}
    </Select>;
}