import { useInjection } from "inversify-react";
import ApiService from "../../services/apiService";
import React, { useCallback, useEffect, useState } from "react";
import { ApplicationUserApi, ApplicationUserDto, DepartmentApi, PeopleDetailsDto } from "../../api";
import { loadingSpin, RoutingPaths } from "../routing/routingContainer";
import GweLayout from "../../components/gweLayout/gweLayout";
import { Button, Col, Divider, List, Result, Space, Spin, Tag, Typography } from "antd";
import { UserTags } from "../../components/userTile/userTile";
import { Link } from "react-router-dom";
import styles from "./people.module.less";
import ProfilePicture from "../../components/profilePicture/profilePicture";
import DepartmentSelector from "../../components/departmentSelect/departmentSelect";
import { useDispatch, useSelector } from "react-redux";
import { getDepartments, setDepartments } from "../../redux/productSlice";

const { Text } = Typography;

export default function UnlimitedConsults(props: { editable?: boolean }) {
    const apiService = useInjection(ApiService);
    const dispatch = useDispatch();

    const [ users, setUsers ] = useState<ApplicationUserDto[] | null>();

    useEffect(() => {
        const updateDepartments = async () => {
            const response = await apiService.getApi(DepartmentApi).apiDepartmentGet();

            dispatch(setDepartments(response.data));
        }

        updateDepartments();
    }, [apiService]);

    const getExpertEmployees = useCallback(async () => {
        return await apiService.getApi(ApplicationUserApi).apiApplicationUserManagersGet();
    }, [ apiService ]);

    const refreshUserList = useCallback(async () => {
        try {
            const response = await getExpertEmployees();
            setUsers(response.data);
        } catch (e: any) {
            console.log(e.response?.data);
        }
    }, [ getExpertEmployees ]);

    useEffect(() => {
        refreshUserList();
    }, [ refreshUserList ]);

    const filtered = props.editable ? users : users?.filter(u => u.departments?.length);

    if (!!filtered && filtered.length === 0) {
        return <GweLayout title="Nielimitowane konsultacje">
            <>
                <br/>
                <Result
                    title="Wygląda na to że nie masz przydzielonego żadnego managera."
                    extra={
                        <Link to={RoutingPaths.mainPage.route}>
                            <Button type="primary" key="console">
                                Powrót
                            </Button>
                        </Link>
                    }
                />
            </>
        </GweLayout>;
    }

    return <GweLayout
        title="Nielimitowane konsultacje"
        subtitle={users ? `(${users?.length} pozycji)` : undefined}
    >
        <Col xs={22} md={20} lg={18} xxl={16}>
            <ContactTile />
            {!(users || null)
                ? loadingSpin
                : <ManagersList filtered={filtered || undefined} editable={props.editable} />
            }
        </Col>
    </GweLayout>
}

function ContactTile() {
    return <Space direction="vertical" style={{ width: "100%" }}>
        <Divider>Kontakt w sprawie konsultacji</Divider>
        <Space><Text type="secondary">E-mail:</Text><a href="mailto:konsultacje@gwexperts.pl">konsultacje@gwexperts.pl</a></Space>
        <Space><Text type="secondary">Telefon:</Text><a href="tel:+48608162567">608 162 567</a></Space>
        <Divider />
    </Space>
}

function ManagersList({filtered, editable} : { filtered?: ApplicationUserDto[], editable?: boolean }) {
    return <>
        <List
            grid={{ gutter: 24, column: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
            dataSource={filtered}
            renderItem={user => <List.Item key={user.id}>
                <ManagerTile user={user} editable={editable} />
            </List.Item>}
        />
    </>
}

function ManagerTile({user, editable}: { user: ApplicationUserDto, editable?: boolean }) {

    return <div
        className={styles.tileContainer}>
        <div className={styles.userInfoWrapper}>
            <div className={styles.profileWrapper}>
                <ProfilePicture className={styles.userProfilePicture}
                                profilePictureId={user.profileImageId} />
                <UserTags user={user} />
            </div>
            <div className={styles.rightColumn}>
                <div className={styles.userInfo}>
                    <div className={styles.userName}>{user.firstName} {user.lastName}</div>
                    <em>{(user.managerTitle?.name || "Manager")}</em>
                </div>
                <div className={styles.userInfo}>
                    <ManagerDepartments user={user} editable={editable} />
                </div>
            </div>
        </div>
    </div>
}

function ManagerDepartments({user, editable} : { user: ApplicationUserDto, editable?: boolean }) {

    const apiService = useInjection(ApiService);
    const [isLoading, setIsLoading] = useState(false);
    const [departments, setDepartments] = useState(user.departments);
    const allDepartments = useSelector(getDepartments);

    const updateDepartments = async (ids: number[]) => {
        setIsLoading(true);

        try {
            const response = await apiService.getApi(ApplicationUserApi).apiApplicationUserDepartmentsIdPost(user.id!, ids);

            setDepartments(response.data.departments)
        } finally {
            setIsLoading(false);
        }
    }

    const onChangeOnPosition = (value: number, key: number) => {
        let updated = [...(departments || [])];

        updated[key] = value;

        updateDepartments(updated.filter(u => u));
    }

    const onAddedNew = (value: number) => {
        updateDepartments([...(departments || []), value]);
    }

    return <Spin spinning={isLoading}>
        <div className={styles.departments}>
            <Text type="secondary">Obszary specjalizacji managera:</Text>
            <div className={styles.departmentList}>
                {editable && departments?.map((d, i) =>
                    <DepartmentSelector key={i} value={d} onChange={v => onChangeOnPosition(v, i)} />)}
                {editable && <DepartmentSelector value={null} onChange={onAddedNew} key={"new"} />}
                {!editable && departments?.map((id, i) => <Tag key={i}>{allDepartments?.find(d => d.id === id)?.name?.replace(/ *\([^)]*\) */g, "")}</Tag>)}
            </div>
        </div>
    </Spin>
}