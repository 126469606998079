import {
    BankOutlined, CalendarOutlined,
    ContainerOutlined,
    MailOutlined,
    MinusOutlined,
} from "@ant-design/icons";
import {Tooltip} from "antd";
import {Link} from "react-router-dom";
import {ApplicationUserRole, ApplicationUserStatus} from "../../api";
import {UserTileComponentProps} from "./userTile";
import styles from "./userTile.module.less"
import {RoutingPaths} from "../../pages/routing/routingContainer";

export default function UserTileOptions(props: UserTileComponentProps) {

    if (props.user.status === ApplicationUserStatus.Deleted) {
        return <MinusOutlined/>;
    }

    return <>
        <Tooltip overlayInnerStyle={{fontSize: 14}} mouseEnterDelay={0.05} title="Wyślij wiadomość" placement="bottom">
            <Link to="." className={styles.iconButton}>
                <MailOutlined/>
            </Link>
        </Tooltip>
        <div className={styles.verticalSeparator}/>
        {props.user.roles?.includes(ApplicationUserRole.Client) && <>
            <Tooltip overlayInnerStyle={{fontSize: 14}} mouseEnterDelay={0.05} title="Sprawy klienta"
                     placement="bottom">
                <Link to={props.casesPath} className={styles.iconButton}>
                    <ContainerOutlined/>
                </Link>
            </Tooltip>
            <div className={styles.verticalSeparator}/>
            <Tooltip overlayInnerStyle={{fontSize: 14}} mouseEnterDelay={0.05} title="Kalendarz klienta"
                     placement="bottom">
                <Link to={RoutingPaths.userCalendar.route.replace(":userId", props.user.id!.toString())}
                      className={styles.iconButton}>
                    <CalendarOutlined/>
                </Link>
            </Tooltip>
            <div className={styles.verticalSeparator}/>
        </>}

        <Tooltip overlayInnerStyle={{fontSize: 14}} mouseEnterDelay={0.05} title="Firmy klienta" placement="bottom">
            <Link to="." className={styles.iconButton}>
                <BankOutlined/>
            </Link>
        </Tooltip>
    </>;
}
